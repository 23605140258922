import React from "react";
import "./csae.css";
import NavbarSection from "../../NavBar/Navbar.js";
import FooterSection from "../../NewFooter/Footer.js";

function CSAEPolicy() {
  document.title = "3VO | CSAE Policy";

  return (
    <div className="bg-pages">
      <NavbarSection />
      <div className="embed rapper-style">
        <div className="embed-container">
          <h1 className="embed-title">
            3VO Standards against Child Sexual Abuse and Exploitation (CSAE)
          </h1>
          {/* <small className="embed-date">Last modified date: 20/01/2025</small> */}

          <div className="embed-privacy">
            <h2 className="embed-privacy-inner-titless">Child Safety</h2>
            <p>{/* <strong>January 2025</strong> */}</p>
            <p>
              We have zero tolerance for any forms of child sexual exploitation
              and remove certain media depicting physical child abuse to prevent
              the normalization of violence against children.
            </p>
            <p>
              Our Child Safety policy is designed to protect minors from sexual
              and physical abuse, as well as psychological harm that may result
              from sharing such content. With this principle in mind, Child
              Safety content is any content that contains Child Sexual
              Exploitation, Physical Child Abuse Media, and Media of Minors in
              Physical Altercation, as defined below.
            </p>

            <h2 className="embed-privacy-inner-titless">
              Child Sexual Exploitation
            </h2>
            <p>
              3VO.ME has zero tolerance towards any material that features or
              promotes child sexual exploitation. This may include real media,
              text, illustrated, or computer-generated media - including
              generative AI media.
            </p>

            <h3 className="embed-privacy-inner-titless">Not Allowed</h3>
            <ul>
              <li>
                1- Depictions of a child engaging in sexually explicit or
                suggestive acts.
              </li>
              <li>2- Sexualized commentaries about or directed at a minor.</li>
              <li>
                3- Links to third-party sites that host child sexual
                exploitation materials.
              </li>
              <li>
                4- Fantasies about or promoting engagement in child sexual
                exploitation.
              </li>
            </ul>

            <h3 className="embed-privacy-inner-titless">Allowed</h3>
            <ul>
              <li>
                1- Discussion related to child sexual exploitation that does not
                glorify or promote it.
              </li>
              <li>
                2- Victims sharing their stories, provided they do not contain
                explicit details.
              </li>
              <li>
                3- Advocacy against illegal or harmful activities involving
                minors.
              </li>
            </ul>

            <h2 className="embed-privacy-inner-titless">
              Physical Child Abuse Media
            </h2>
            <p>
              To prevent the revictimization or normalization of violence
              against children, we will remove most instances of media depicting
              physical child abuse, even if shared to raise awareness or express
              outrage.
            </p>

            <h3 className="embed-privacy-inner-titless">Not Allowed</h3>
            <ul>
              <li>
                Media where the child is nude and experiencing any type of harm.
              </li>
              <li>Media shared with abusive context.</li>
              <li>
                Media where the child is experiencing moderate or major harm.
              </li>
            </ul>

            <h2 className="embed-privacy-inner-titless">How to Report</h2>
            <p>
              Anyone can report violations of this policy by sending a message
              to our dedicated e-mail address:
              <a href="mailto:report@3vo.me">report@3vo.me</a>. If you believe a
              child is at immediate risk of harm, please contact local law
              enforcement.
            </p>
            <p>
              Additionally, you can notify the National Center for Missing &
              Exploited Children (NCMEC) by using their
              <a
                href="https://report.cybertip.org/"
                target="_blank"
                rel="nofollow"
              >
                CyberTipline
              </a>{" "}
              portal or one of the organizations listed in your country at{" "}
              <a
                target="_blank"
                href="https://support.google.com/websearch/answer/148666"
                rel="nofollow"
              >
                this link
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
}

export default CSAEPolicy;
