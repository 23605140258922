import React from "react";
import "./AdvisorsSection.css";
// import image from "../../../../assets/svg/member-image.png";
import Glenn from "../../../../assets/NewAdvisors/glenn-ezgif1.webp";
import tomer from "../../../../assets/NewAdvisors/tomer1.webp";
import ali from "../../../../assets/NewAdvisors/ali1.webp";
import ibra from "../../../../assets/NewAdvisors/ibrahim1.webp";
import step from "../../../../assets/NewAdvisors/stephan-ezgif1.webp";
import tal from "../../../../assets/NewAdvisors/tal1.webp";
import alex from "../../../../assets/NewAdvisors/alex1.webp";
import Jasenko from "../../../../assets/NewAdvisors/jasenko-ezgif1.webp";
import Wojciech from "../../../../assets/NewAdvisors/wojtek1.webp";
export const AdvisorsSection = () => {
  return (
    <div className="advisors-section container">
      <h1 className="advisors-title">Advisors</h1>
      <div className="advisors-conatiner-wrapper">
        <div className="advisors-conatiner">
          <div className="advisors-left">
            <div className="adivors-member">
              <img
                loading="lazy"
                src={Glenn}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Glenn Grant</p>
                <p className="advisors-position">Institutional</p>
                <p className="advisors-description">
                  Former Director at Kraken and Saxo Bank. 30+ years in
                  traditional and fintech industries across EU, MENA, Asia, and
                  the United States.
                </p>
              </div>
            </div>
            <div className="adivors-member">
              <img
                loading="lazy"
                src={ali}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Ali Aoun</p>
                <p className="advisors-position">Compliance</p>
                <p className="advisors-description">
                  Global compliance expert. Currently at Wells Fargo. Formerly
                  with JP Morgan, Rabobank, Department of State, and US Army.
                </p>
              </div>
            </div>
            <div className="adivors-member">
              <img
                loading="lazy"
                src={step}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Stephan Milacek</p>
                <p className="advisors-position">Hiring</p>
                <p className="advisors-description">
                  Former CEO of cryptojobs.com and a core team member at
                  DAOMaker. Currently leads talent acquisition for Step-in.
                </p>
              </div>
            </div>
            <div className="adivors-member">
              <img
                loading="lazy"
                src={alex}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Alex Roa</p>
                <p className="advisors-position">Technology</p>
                <p className="advisors-description">
                  CEO - The Culture Club and blitzpay. Manages and facilitates
                  dealflow and payments between over 15,000 influencers
                  globally.
                </p>
              </div>
            </div>
          </div>
          <div className="advisors-right">
            <div className="adivors-member">
              <img
                loading="lazy"
                src={tomer}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Tomer Nuni</p>
                <p className="advisors-position">Strategy / Fundraising</p>
                <p className="advisors-description">
                  Leads the investment arm of ChainGPT Labs. CMO at Kima
                  Finance. Writer for Cointelegraph and Forbes. Played a key
                  role in incubating ChainGPT.
                </p>
              </div>
            </div>
            <div className="adivors-member">
              <img
                loading="lazy"
                src={ibra}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Ibrahim Ezzedine</p>
                <p className="advisors-position">Onboarding/Education</p>
                <p className="advisors-description">
                  Founder of Gritnova Global Campus. Previously engaged in
                  projects with the United Nations and the World Bank.
                </p>
              </div>
            </div>
            <div className="adivors-member">
              <img
                loading="lazy"
                src={tal}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Tal Cohen</p>
                <p className="advisors-position">Institutional</p>
                <p className="advisors-description">
                  Managing Director at Kraken Digital Asset Exchange US and CEO
                  of Payward Ventures Inc.
                </p>
              </div>
            </div>
            <div className="adivors-member">
              <img
                loading="lazy"
                src={Jasenko}
                alt="advisors-member"
                className="advisors-member-image"
              />
              <div className="adivsors-name-position">
                <p className="advisors-name">Jasenko Purkovic</p>
                <p className="advisors-position">Technology</p>
                <p className="advisors-description">
                  Tech consultant with 15+ years of IoT experience. Director of
                  Weehodi. 12 years in blockchain/crypto, investor in 50+
                  projects.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="adivors-member-last">
          <img
            loading="lazy"
            src={Wojciech}
            alt="advisors-member"
            className="advisors-member-image"
          />
          <div className="adivsors-name-position">
            <p className="advisors-name">Wojciech Mrówka</p>
            <p className="advisors-position">Strategy</p>
            <p className="advisors-description">
              COO Cookie3, Core Contributor Cookie DAO
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
