import React from "react";
import "./JoinCommunity.css";
import tiktok from "../../assets/svg/Tiktok (with blue).svg";
import arrowUp from "../../assets/svg/arrowupCard.svg";
import insta from "../../assets/svg/instaLogo.svg";
import telegram from "../../assets/svg/telegramLogo.svg";
import twitter from "../../assets/svg/X.svg";
import youtube from "../../assets/svg/Youtube.svg";
import linkedIn from "../../assets/svg/Linkedin.svg";
export const JoinCommunity = () => {
  return (
    <div className="JoinCommunity-section container">
      <h2 className="JoinCommunity-title">Join Our Community</h2>
      <div className="JoinCommunity-socials">
        <div className="JoinCommunity-socials-row">
          <div className="JoinCommunity-Advantage-card">
            <div className="Advantage-card-header">
              <p className="Advantage-card-header-title">
                {" "}
                X <span>(twitter)</span>
              </p>
              {/* <p className="Advantage-card-header-number">40K+</p> */}
            </div>
            <a href="https://x.com/3vo_me" target="_blank" rel="noreferrer">
              <div className="Advantage-card-logo">
                <img src={twitter} alt="tiktok" className="social-logo" />
              </div>
            </a>
            <div className="Advantage-card-link">
              <p className="Advantage-card-join">Join Now</p>
              <img src={arrowUp} alt="arrowup" className="card-arrow" />
            </div>
          </div>
          <div className="JoinCommunity-Advantage-card">
            <div className="Advantage-card-header">
              <p className="Advantage-card-header-title">Telegram</p>
              {/* <p className="Advantage-card-header-number">40K+</p>   */}
            </div>
            <a
              href="https://t.me/Official_3VO"
              target="_blank"
              rel="noreferrer"
            >
              <div className="Advantage-card-logo">
                <img src={telegram} alt="tiktok" className="social-logo" />
              </div>
            </a>
            <div className="Advantage-card-link">
              <p className="Advantage-card-join">Join Now</p>
              <img src={arrowUp} alt="arrowup" className="card-arrow" />
            </div>
          </div>
          <div className="JoinCommunity-Advantage-card">
            <div className="Advantage-card-header">
              <p className="Advantage-card-header-title">Instagram</p>
              {/* <p className="Advantage-card-header-number">40K+</p> */}
            </div>
            <a
              href="https://www.instagram.com/3vo_me"
              target="_blank"
              rel="noreferrer"
            >
              <div className="Advantage-card-logo">
                <img src={insta} alt="tiktok" className="social-logo" />
              </div>
            </a>
            <div className="Advantage-card-link">
              <p className="Advantage-card-join">Join Now</p>
              <img src={arrowUp} alt="arrowup" className="card-arrow" />
            </div>
          </div>
        </div>
        <div className="JoinCommunity-socials-row">
          <div className="JoinCommunity-Advantage-card">
            <div className="Advantage-card-header">
              <p className="Advantage-card-header-title">Tiktok</p>
              {/* <p className="Advantage-card-header-number">40K+</p> */}
            </div>
            <a
              href="https://www.tiktok.com/@3vo_official"
              target="_blank"
              rel="noreferrer"
            >
              <div className="Advantage-card-logo">
                <img src={tiktok} alt="tiktok" className="social-logo" />
              </div>
            </a>
            <div className="Advantage-card-link">
              <p className="Advantage-card-join">Join Now</p>
              <img src={arrowUp} alt="arrowup" className="card-arrow" />
            </div>
          </div>
          <div className="JoinCommunity-Advantage-card">
            <div className="Advantage-card-header">
              <p className="Advantage-card-header-title">YouTube</p>
              {/* <p className="Advantage-card-header-number">40K+</p> */}
            </div>
            <a
              href="https://www.youtube.com/@3vo-me"
              target="_blank"
              rel="noreferrer"
            >
              <div className="Advantage-card-logo">
                <img src={youtube} alt="tiktok" className="social-logo" />
              </div>
            </a>
            <div className="Advantage-card-link">
              <p className="Advantage-card-join">Join Now</p>
              <img src={arrowUp} alt="arrowup" className="card-arrow" />
            </div>
          </div>
          <div className="JoinCommunity-Advantage-card">
            <div className="Advantage-card-header">
              <p className="Advantage-card-header-title">LinkedIn</p>
              {/* <p className="Advantage-card-header-number">40K+</p> */}
            </div>
            <a
              href="https://www.linkedin.com/company/3vo-me/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="Advantage-card-logo">
                <img src={linkedIn} alt="tiktok" className="social-logo" />
              </div>
            </a>
            <div className="Advantage-card-link">
              <p className="Advantage-card-join">Join Now</p>
              <img src={arrowUp} alt="arrowup" className="card-arrow" />
            </div>
          </div>
          {/* <div className="JoinCommunity-Advantage-card"></div>
          <div className="JoinCommunity-Advantage-card"></div>
          <div className="JoinCommunity-Advantage-card"></div> */}
        </div>
      </div>
    </div>
  );
};
