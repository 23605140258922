import React, { useState, useRef, useEffect } from "react";
import "../NavBar/Navbar.css";
import lunchBtn from "../../assets/svg/lunchApp.svg";
import newLogo from "../../assets/svg/new-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";

const NavbarSection = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.closest(".header-burger-menu")
      ) {
        setIsMobileMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  return (
    <div className="bg-pages">
      <header className="new-header">
        <div className="header-main container">
          <a href="/">
            <img className="nav-logo" alt="Logo" src={newLogo} />
          </a>

          <nav className="sections">
            <a href="/newfeature" className="sections-text-wrapper">
              Features
            </a>
            <a href="/newtoken" className="sections-text-wrapper">
              Token
            </a>
            <a href="/newabout" className="sections-text-wrapper">
              About
            </a>
            <a href="https://blog.3vo.me/" className="sections-text-wrapper">
              Blog
            </a>
            <a href="https://docs.3vo.me/" className="sections-text-wrapper">
              Docs
            </a>
          </nav>

          <div className="lunchApp-btn">
            <a href="https://app.3vo.me" target="_blank" rel="noreferrer">
              <img src={lunchBtn} alt="Launch App" />
            </a>
          </div>

          <div className="header-burger-menu" onClick={toggleMobileMenu}>
            <FontAwesomeIcon
              icon={isMobileMenuOpen ? faXmark : faBars}
              className="header-burger-menu-icon"
            />
          </div>
        </div>

        {isMobileMenuOpen && (
          <div ref={mobileMenuRef} className="header-mobile-menu">
            <div className="header-mobile-logo">
              <a href="/">
                <img
                  src={newLogo}
                  className="header-innerlogo-new"
                  alt="Logo"
                />
              </a>
            </div>
            <nav className="mobile-nav-m">
              <a href="newfeature" className="submenu-m">
                Features
              </a>
              <a href="/newtoken" className="submenu-m">
                Token
              </a>
              <a href="/newabout" className="submenu-m">
                About
              </a>
              <a href="https://blog.3vo.me/" className="submenu-m">
                Blog
              </a>
              <a href="https://docs.3vo.me/" className="submenu-m">
                Docs
              </a>
            </nav>
          </div>
        )}
      </header>
    </div>
  );
};

export default NavbarSection;
