import React from "react";
import "./RoadmapSection.css";
import road from "../../../../assets/svg/roadExtend.svg";
export const RoadmapSection = () => {
  return (
    <div className="Roadmap-section container">
      <h2 className="roadmap-title">Roadmap</h2>

      <div className="roadmap-wrapper">
        <div className="roadmap-road">
          <div className="roadmap-containers-wrapper">
            <div className="roadmap-left">
              <div className="roadmap-card-1">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2023</p>
                  <p className="roadmap-q">Q3</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Legal Registration
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Team Formation
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Documentation
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Wireframing
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Technical Infrastructure
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-2L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q1</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO DeFi Wallet
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO App (Alpha Build)
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-2L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q3</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Android App (Public)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Early Creators OnBording
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Test Market Deployment
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Private Investment Round
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-4L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q1</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Listing
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Web (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VOgram (Telegram Miniapp) API
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Ai Agents (Alpha)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Creator (Alpha -
                    Testnet)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Quests (Alpha)
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-4L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q3</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Solana Integration
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>CeX Integration
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Creator (Solana)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Ai Agents (Public)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Pages Customization (Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Quests (Full Release)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Crypto ePos
                  </li>
                </ul>
              </div>
            </div>
            {/* */}
            <img src={road} alt="roadmap-road" className="line-road" />
            <div className="roadmap-right">
              <div className="roadmap-card-2">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2023</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Angel Round
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Token Smart Contract
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Backend & Internal Builds
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-2R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q2</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Android App (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Seed Round
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-3R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Co-Marketing With 30 Partners
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Web (Alpha)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Community Building
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>User Acquisition Campaign
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-4R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q2</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Creator (Mainnet
                    Base/BNB/Eth)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Ai Agents (Private Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Pages Customization (Alpha)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Quests (Public Beta) Support
                    for all EVM Chains
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-4R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Page Customization (Full)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>SDK
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO DAO
                  </li>
                  {/* <li>
                    {" "}
                    <span className="dot">•</span>Quests (Public Beta) Support
                    for all EVM Chains
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="roadmap-containers-wrapper-M">
            <div className="roadmap-left">
              <div className="roadmap-card-1">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2023</p>
                  <p className="roadmap-q">Q3</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Legal Registration
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Team Formation
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Documentation
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span> Wireframing
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Technical Infrastructure
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-2L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2023</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Angel Round
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Token Smart Contract
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Backend & Internal Builds
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-2L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q1</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO DeFi Wallet
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO APP (Alpha Build)
                  </li>
                </ul>
              </div>

              <div className="roadmap-card-4L">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q2</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Android App (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Seed Round
                  </li>
                </ul>
              </div>
            </div>
            {/* */}
            {/* <img src={road} alt="roadmap-road" /> */}
            <div className="roadmap-right">
              <div className="roadmap-card-2">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q3</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Android App (Public)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Early Creators OnBording
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Test Market Deployment
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Private Investment Round
                  </li>
                </ul>
              </div>
              {/* <div className="roadmap-card-2R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO App (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Seed Round
                  </li>
                </ul>
              </div> */}
              <div className="roadmap-card-3R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2024</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Co-Marketing With 30 Partners
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Web (Alpha)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Community Building
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>User Acquisition Campaign
                  </li>
                  {/* <li>
                    {" "}
                    <span className="dot">•</span>KOL Campaign
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>PR Campaign
                  </li> */}
                </ul>
              </div>
              <div className="roadmap-card-4R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q1</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Listing
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO Web (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VOgram (Telegram Miniapp) API
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Ai Agent (Alpha)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Creator (Alpha -
                    Testnet)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span> Quests (Alpha)
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-4R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q2</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Creator (Mainnet
                    Base/BNB/Eth)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Ai Agents (Private Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Pages Customization (Alpha)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Quests (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Support for all EVM Chains
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-4R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q3</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Solana Integration
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>CeX Integration
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Token Creator (Solana)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Ai Agents (Public)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Pages Customization (Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Quests (Full Release)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Crypto ePos
                  </li>
                </ul>
              </div>
              <div className="roadmap-card-4R">
                <div className="roadmap-card-header">
                  <p className="roadmap-year">2025</p>
                  <p className="roadmap-q">Q4</p>
                </div>
                <ul className="roadmap-pargraph">
                  <li>
                    {" "}
                    <span className="dot">•</span>Page Customization (Full)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>SDK
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>3VO DAO
                  </li>
                  {/* <li>
                    {" "}
                    <span className="dot">•</span>Quests (Public Beta)
                  </li>
                  <li>
                    {" "}
                    <span className="dot">•</span>Support for all EVM Chains
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoadmapSection;
