import React from "react";
import "./BridgingSection.css";

export const BridgingSection = () => {
  return (
    <div className="bridging-firt-frame container">
      <div className="bridging-second-reactangle">
        <p className="bridging-title">
          Redefining the Future of Digital Communities
        </p>
        {/* <p className="bridging-pargraph">
          3VO provides an integrated ecosystem for content monetization,
          eliminating fragmentation and streamlining operations. Its{" "}
          <span> AI-driven </span>
          automation enhances content management, community engagement, and
          scalability, enabling platforms to deliver seamless user experiences
          effortlessly. <br />
          <br />
          With advanced personalization and all-in-one solutions — such as token
          launchpad, AI content creation, marketplaces, community chats, and
          multi-chain wallet — anyone can build thriving communities without
          relying on disjointed tools. Interactive and rewarding engagement
          methods keep users invested, transforming passive audiences into
          active participants and driving long-term loyalty in an era of endless
          digital content.
        </p> */}
        <p className="bridging-pargraph">
          <span>3VO</span> provides an integrated ecosystem for{" "}
          <span>content monetization</span>, eliminating fragmentation and
          streamlining operations. Its
          <span> AI-driven</span> automation enhances{" "}
          <span>content management</span>,<span>community engagement</span>, and
          scalability, enabling platforms to deliver seamless{" "}
          <span>user experiences</span> effortlessly. <br />
          <br />
          With advanced <span>personalization</span> and all-in-one solutions —
          such as
          <span> token launchpad</span>, <span>AI content creation</span>,
          <span>marketplaces</span>, <span>community chats</span>, and
          <span> multi-chain wallet</span> — anyone can build thriving{" "}
          <span> communities </span>
          without relying on disjointed tools. <span>Interactive</span> and
          <span> rewarding engagement</span> methods keep users invested,
          transforming
          <span> passive audiences</span> into <span>active participants</span>{" "}
          and driving long-term <span>loyalty</span> in an era of endless
          <span> digital content</span>.
        </p>
      </div>
      <div className="bridging-first-reactangle"></div>
    </div>
  );
};
export default BridgingSection;
