import React from "react";
import "./TermsOfUse.css";
import NavbarSection from "../../NavBar/Navbar.js";
import FooterSection from "../../NewFooter/Footer.js";

function TermsOfUse() {
  document.title = "3VO | Terms of Use";

  return (
    <div className="bg-pages">
      <NavbarSection />
      <div className="embed">
        <div className="embed-container">
          <div className="embed-title">Terms Of Use</div>
          {/* <div className="embed-date">Last modified date: 20/11/2023</div> */}
          <div className="embed-privacy">
            <p>
              <strong>
                IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING
                ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN
                SECTION 11. PLEASE READ THE AGREEMENT CAREFULLY.
              </strong>
              <br />
              <br />
              <h1>3VO S.A.</h1>
              <p>
                3VO S.A. (“3vo,” “we,” “us,” or “our”) is a blockchain software
                development company. It allows users to access their wallet
                through a browser extension or mobile app, which can then be
                used to interact with decentralized applications. With a focus
                on utilizing decentralized technologies, our software is
                powering a revolution in social media and finance and helping to
                optimize business processes. 3vo hosts a top-level domain
                website, www.3vo.me, that serves information regarding 3vo and
                our products and services (collectively, the “Offerings”), as
                well as sub-domains for our products or services (the top-level
                domain with the sub-domains collectively referred to as the
                “Site”), which include text, images, audio, code, and other
                materials or third-party information.
                <br />
                <br />
                These Terms of Use (the “Terms,” “Terms of Use,” or “Agreement”)
                contain the terms and conditions that govern your access to and
                use of the Site and Offerings provided by us and is an agreement
                between us and you or the entity you represent (“you” or
                “your”). Please read these Terms of Use carefully before using
                the Site or Offerings. By using the Site, clicking a button or
                checkbox to accept or agree to these Terms where that option is
                made available, clicking a button to use or access any of the
                Offerings, completing an Order, or, if earlier, using or
                otherwise accessing the Offerings (the date on which any of the
                events listed above occur being the “Effective Date”), you (1)
                accept and agree to these Terms and any additional terms, rules,
                and conditions of participation issued by 3vo from time to time
                and (2) consent to the collection, use, disclosure, and other
                handling of information as described in our Privacy Policy. If
                you do not agree to the Terms or perform any and all obligations
                you accept under the Terms, then you may not access or use the
                Offerings.
                <br />
                <br />
                You represent to us that you are lawfully able to enter into
                contracts. If you are entering into this Agreement for an
                entity, such as the company you work for, you represent to us
                that you have legal authority to bind that entity. Please see
                Section 13 for definitions of certain capitalized terms used in
                this Agreement.
                <br />
                <br />
                In addition, you represent to us that you and your financial
                institutions, or any party that owns or controls you or your
                financial institutions, are (1) not subject to sanctions or
                otherwise designated on any list of prohibited or restricted
                parties, including but not limited to the lists maintained by
                the United Nations Security Council, the U.S. Government (i.e.,
                the Specially Designated Nationals List and Foreign Sanctions
                Evaders List of the U.S. Department of Treasury and the Entity
                List of the U.S. Department of Commerce), the European Union or
                its Member States, the United Kingdom, or other applicable
                government authority and (2) not located in any country subject
                to a comprehensive sanctions program implemented by the United
                States.
                <br />
                <br />
                1. The Offerings. 1.1 Generally. You may access and use the
                Offerings in accordance with this Agreement. You agree to comply
                with the terms of this Agreement and all laws, rules, and
                regulations applicable to your use of the Offerings.
                <br />
                <br />
                1.2 Offerings and Access. 3vo offers a number of Offerings under
                the 3vo brand or brands owned by us. These include 3vo App, and
                others. Offerings are generally accessed through the Site or
                through a third-party provider of which we approved, such as the
                Google Play or Apple App stores, unless otherwise agreed in
                writing. Some Offerings may require you to create an account
                with 3vo, enter a valid form of payment, and select a paid plan
                (a “Paid Plan”), or initiate an Order.
                <br />
                <br />
                1.3 Third-Party Content and Services. In certain Offerings,
                including 3vo App Swaps, Bridging, Staking, and Snaps, you may
                view, have access to, and may use the informational content,
                products, or services of one or more third parties (“Third Party
                Content” and “Third Party Services” respectively). In each such
                case, you agree that you view, access or use such content and
                services at your own election. Your reliance on any Third Party
                Content and use of Third Party Services in connection with the
                Offerings is governed on one hand by this Agreement but, on the
                other, will also generally be subject to separate terms and
                conditions set forth by the applicable third party content
                and/or service provider. Those terms and conditions may involve
                separate fees and charges or may include disclaimers or risk
                warnings about reliance on or the accuracy of any information.
                Such terms may also apply a privacy policy different than that
                which 3vo maintains and incorporates into this Agreement. It is
                your responsibility to understand the terms and conditions of
                Third Party Services, including how those service providers use
                any of your information under their privacy policies.
                <br />
                <br />
                Third Party Content and Third Party Services are provided for
                your convenience only. We do not verify, curate, or control
                Third Party Content. We do not control Third Party Services. As
                a result, we do not guarantee, endorse, or recommend such
                content or services to any or all users of the Offerings, or the
                use of such content or services for any particular purpose. You
                access, rely upon or use any Third Party Content or Third Party
                Service at your own risk. 3vo disclaims all responsibility and
                liability for any Losses on account of your reliance upon or use
                of such content or services. We have no responsibility for Third
                Party Content that may be misleading, incomplete, erroneous,
                offensive, indecent, or otherwise objectionable to you or under
                the law in your jurisdiction. The choice to rely on Third Party
                Content or to use a Third Party Service is your own, and you are
                solely responsible for ensuring that your reliance or use is in
                compliance with all applicable laws. Dealing or correspondence
                with any third party that provides such content or services is
                solely between you and that third party. We reserve the right to
                change, suspend, remove, disable, or impose access restrictions
                or limits on the use of any Third Party Service at any time
                without notice.
                <br />
                <br />
                2. Changes.
                <br />
                <br />
                2.1 To the Offerings. We may change or discontinue any or all of
                the Offerings or change or remove functionality of any or all of
                the Offerings from time to time. We will use commercially
                reasonable efforts to communicate to you any discontinuation of
                an Offering through the Site or public communication channels.
                If you are on a Paid Plan, we will use commercially reasonable
                efforts to communicate to you any discontinuation of the
                Offering at least 30 days in advance of such discontinuation,
                and we will use commercially reasonable efforts to continue
                supporting the Offering for up to three months after the
                discontinuation, except if doing so (a) would pose an
                information security or intellectual property issue, (b) is
                economically or technically burdensome, or (c) would create
                undue risk of us violating the law.
                <br />
                <br />
                2.2 To this Agreement. We reserve the right, at our sole
                discretion, to modify or replace any part of this Agreement or
                any Policies at any time. It is your responsibility to check
                this Agreement periodically for changes, but we will also use
                commercially reasonable efforts to communicate any material
                changes to this Agreement through the Site, email (if you have
                an account), or public channels. You agree that your continued
                use of or access to the Offerings following the posting of any
                changes to this Agreement constitutes acceptance of those
                changes, whether or not you were checking for changes or
                actually read the changes.
                <br />
                <br />
                3. Your Responsibilities.
                <br />
                <br />
                3.1 Use of the Offerings. For any Offerings, whether they
                require that you set up an account with 3vo or they do not, and
                except to the extent caused by our breach of this Agreement, (a)
                you are responsible for all activities that occur with respect
                to your use of the Offerings, regardless of whether the
                activities are authorized by you or undertaken by you, your
                employees or a third party (including your contractors, agents
                or other End Users), and (b) we and our affiliates are not
                responsible for unauthorized access to the Offerings or your
                account, including any access that occurred as a result of
                fraud, phishing, or other criminal activity perpetrated against
                you by third parties. You will ensure that your use of the
                Offerings does not violate any applicable law.
                <br />
                <br />
                3.2 Your Security and Backup. You are solely responsible for
                properly configuring and using the Offerings and otherwise
                taking appropriate action to secure, protect, and backup your
                accounts and/or Your Content in a manner that will provide
                appropriate security and protection, which might include the use
                of encryption. If you are not able to be responsible for your
                own account security or do not want such an obligation, then you
                should not use the Offerings. Your obligations under this
                Agreement include ensuring any available software updates or
                upgrades to an Offering you are using are promptly installed or
                implemented, and recording and securely maintaining any
                passwords or secret recovery phrases that relate to your use of
                the Offerings. You acknowledge that certain methods of securing
                your secret recovery phrase, such as storing it as a digital
                file on your personal device or on a cloud storage provider, may
                increase the risk that your account or secret recovery phrase
                will be compromised. You further acknowledge that you will not
                share with us nor any other third party any password or secret
                recovery phrase that relates to your use of the Offerings, and
                that we will not be held responsible if you do share any such
                password or phrase, whether you do so knowingly or unknowingly.
                For the avoidance of doubt, we take no responsibility whatsoever
                for any theft of a secret recovery phrase that involved
                intrusion into a cloud provider’s data repository.
                <br />
                <br />
                3.3 Log-In Credentials and API Authentication. To the extent we
                provide you with log-in credentials and API authentication
                generated by the Offerings, such log-in credentials and API
                authentication are for your use only, and you will not sell,
                transfer, or sublicense them to any other entity or person,
                except that you may disclose your password or private key to
                your agents and subcontractors performing work on your behalf.
                <br />
                <br />
                3.4 Applicability to Offerings that facilitate access to
                addresses on blockchain protocols. For the avoidance of doubt,
                the terms of this Section 3 are applicable to all Offerings such
                as 3vo App through which you generate a public/private key pair
                (which can be thought of as a blockchain account and related
                password) either with a blockchain protocol directly or with
                Third Party Offerings, such as decentralized applications. You
                are solely responsible for the use and security of these
                security keys and that we will not be held responsible if you
                share any keys or secret recovery phrases with anyone else,
                whether knowingly or unknowingly.
                <br />
                <br />
                4. Fees and Payment.
                <br />
                <br />
                4.1 Publicly Available Offerings. Some Offerings may be offered
                to the public and licensed on a royalty-free basis, including
                Offerings that require a Paid Plan for software licensing fees
                above a certain threshold of use. These terms apply to all
                Offerings regardless of whether they require a Paid Plan.
                <br />
                <br />
                4.2 Offering Fees. If your use of an Offering does not require
                an Order or Paid Plan but software licensing fees are charged
                contemporaneously with your use of the Offering, those fees will
                be charged as described on the Site or in the user interface of
                the Offering. Such fees may be calculated by combining a fee
                charged by us and a fee charged by a Third Party Offering that
                provides certain functionality related to the Offering. For
                those Offerings which entail an Order or Paid Plan, we calculate
                and bill fees and charges according to your Order or Paid Plan.
                For such Offerings, on the first day of each billing period, you
                will pay us the applicable fees (the “Base Fees”) and any
                applicable taxes based on the Offerings in the Paid Plan. In
                addition, for particular Orders, we may issue an invoice to you
                for all charges above the applicable threshold for your Paid
                Plan, which constitute overage fees for the previous billing
                period. If you make any other changes to the Offerings during a
                billing period (for example, upgrading or downgrading your Paid
                Plan), we will apply any additional charges or credits to the
                next billing period. We may bill you more frequently for fees
                accrued at our discretion upon notice to you. You will pay all
                fees in U.S. dollars unless the particular Offering specifies a
                different form of payment or otherwise agreed to by you and us
                in writing. All amounts payable by you under this Agreement will
                be paid to us without setoff or counterclaim and without any
                deduction or withholding. Fees and charges for any new Offering
                or new feature of an Offering will be effective when we use
                commercially reasonable efforts to communicate updated fees and
                charges through our Site, the interface of the Offering itself,
                or other public channels, or, if you are on a Paid Plan, upon
                commercially reasonable efforts to notify you directly, but we
                may expressly state when notifying you that another effective
                date applies. We may increase or add new fees and charges for
                any existing Offerings you are using by using commercially
                reasonable efforts to notify users of the Offerings through our
                Site, the interface of the Offering itself, other public
                channels or, if you are on a Paid Plan, by giving you 30 days’
                notice. Unless otherwise specified in an Order, all Paid Plan
                amounts due under this Agreement are payable within 30 days
                following receipt of your invoice. We may elect to charge you
                interest at the rate of 1.5% per month (or the highest rate
                permitted by law, if less) on all late payments.
                <br />
                <br />
                4.3 Taxes. Each party will be responsible, as required under
                applicable law, for identifying and paying all taxes and other
                governmental fees and charges (and any penalties, interest, and
                other additions thereto) that are imposed on that party upon or
                with respect to the transactions and payments under this
                Agreement. All fees payable by you are exclusive taxes unless
                otherwise noted. We reserve the right to withhold taxes where
                required.
                <br />
                <br />
                5. Temporary Suspension; Limiting API Requests.
                <br />
                <br />
                5.1 Generally. We may suspend your right to access or use any
                portion or all of the Offerings immediately if we determine:
                <br />
                <br />
                (a) your use of the Offerings (i) poses a security risk to the
                Offerings or any third party, (ii) could adversely impact our
                systems, the Offerings, or the systems of any other user, (iii)
                could subject us, our affiliates, or any third party to
                liability, or (iv) could be unlawful; (b) you are, or any End
                User is, in breach of this Agreement;
                <br />
                <br />
                (c) you are in breach of your payment obligations under Section
                4 for 30 days or longer; or
                <br />
                <br />
                (d) for entities, you have ceased to operate in the ordinary
                course, made an assignment for the benefit of creditors or
                similar disposition of your assets, or become the subject of any
                bankruptcy, reorganization, liquidation, dissolution or similar
                proceeding.
                <br />
                <br />
                5.2 Effect of Suspension. If we suspend your right to access or
                use any portion or all of an Offering:
                <br />
                <br />
                (a) you remain responsible for all fees and charges you incur
                during the period of suspension; and
                <br />
                <br />
                (b) you will not be entitled to any fee credits for any period
                of suspension.
                <br />
                <br />
                5.3 Limiting API Requests. We retain sole discretion to limit
                your API requests (“API Requests”) submitted in conjunction with
                your use of an Offering at any time if your usage of the
                Offering exceeds the usage threshold specified in your Paid Plan
                or otherwise on the Site or user interface of the Offering.
                Further, excessive API requests, as determined by 3vo in our
                sole discretion, may result in the temporary or permanent
                suspension of your access to an account or to your use of the
                applicable Offering. 3VO is not required but will endeavor, when
                reasonable, to warn an account owner or user prior to
                suspension.
                <br />
                <br />
                6. Term; Termination.
                <br />
                <br />
                6.1 Term. For Offerings subject to a Paid Plan, the term of this
                Agreement will commence on the Effective Date and will remain in
                effect until terminated under this Section 6 or by separate
                written agreement. Any notice of termination of this Agreement
                by either party to the other must include a Termination Date
                that complies with the notice periods in Section 6.2. For
                Offerings that are not subject to a Paid Plan, the term of this
                Agreement will commence on the Effective Date and will remain in
                effect until you stop accessing or using the Offerings.
                <br />
                <br />
                6.2 Termination.
                <br />
                <br />
                (a) Termination for Convenience. If you are not on a Paid Plan,
                you may terminate this Agreement for any reason by ceasing use
                of the Offering. For Paid Plans, 3vo may terminate this
                Agreement for any reason after providing 30 calendar days’
                written notice.
                <br />
                <br />
                (b) Termination for Cause.
                <br />
                <br />
                (i) By Either Party. Either party may terminate this Agreement
                for cause if the other party is in material breach of this
                Agreement and the material breach remains uncured for a period
                of 30 days from receipt of the other party’s notice of breach.
                <br />
                <br />
                (ii) By Us. We may also terminate this Agreement for cause
                immediately (A) if we have the right to suspend under Section 5,
                (B) if our relationship with a third-party partner who provides
                software or other technology we use to provide the Offerings
                expires, terminates, or requires us to change the way we provide
                the software or other technology as part of the Offerings, or
                (C) in order to avoid undue risk of violating the law.
                <br />
                <br />
                6.3 Effect of Termination. Upon the Termination Date:
                <br />
                <br />
                (i) all your rights under this Agreement immediately terminate;
                and
                <br />
                <br />
                (ii) each party remains responsible for all fees and charges it
                has incurred through the Termination Date and are responsible
                for any fees and charges it incurs during the post-termination
                period;
                <br />
                <br />
                (iii) the terms and conditions of this Agreement shall survive
                the expiration or termination of this Agreement to the full
                extent necessary for their enforcement and for the protection of
                the party in whose favor they operate. For instance, should this
                Agreement between you and us terminate, any dispute raised after
                you stop accessing or using the Offerings will be subject to the
                applicable provisions of this Agreement if that dispute relates
                to your prior access or use.
                <br />
                <br />
                For any use of the Offerings after the Termination Date, the
                terms of this Agreement will again apply and, if your use is
                under a Paid Plan, you will pay the applicable fees at the rates
                under Section 4.
                <br />
                <br />
                7. Proprietary Rights.
                <br />
                <br />
                7.1 Your Content. Depending on the Offering, you may share
                Content with us. Except as provided in this Section 7, we obtain
                no rights under this Agreement from you (or your licensors) to
                Your Content; however, you consent to our use of Your Content in
                any manner that is consistent with the purpose of your use of
                the Offerings or that otherwise facilitates providing the
                Offerings to you.
                <br />
                <br />
                7.2 Offerings License. We or our licensors own all right, title,
                and interest in and to the Offerings, and all related technology
                and intellectual property rights. Subject to the terms of this
                Agreement, we grant you a limited, revocable, non-exclusive,
                non-sublicensable, non-transferable license to do the following:
                (a) access and use the Offerings solely in accordance with this
                Agreement; and (b) copy and use Our Content solely in connection
                with your permitted use of the Offerings. Except as provided in
                this Section 7.2, you obtain no rights under this Agreement from
                us, our affiliates or our licensors to the Offerings, including
                any related intellectual property rights. Some of Our Content
                and Third-Party Content may be provided to you under a separate
                license, such as the Apache License, Version 2.0, or other open
                source license. In the event of a conflict between this
                Agreement and any separate license, the separate license will
                prevail with respect to Our Content or Third-Party Content that
                is the subject of such separate license.
                <br />
                <br />
                7.3 License Restrictions. Neither you nor any End User will use
                the Offerings in any manner or for any purpose other than as
                expressly permitted by this Agreement. Except for as authorized,
                neither you nor any End User will, or will attempt to (a)
                modify, distribute, alter, tamper with, repair, or otherwise
                create derivative works of any Content included in the Offerings
                (except to the extent Content included in the Offerings is
                provided to you under a separate license that expressly permits
                the creation of derivative works), (b) reverse engineer,
                disassemble, or decompile the Offerings or apply any other
                process or procedure to derive the source code of any software
                included in the Offerings (except to the extent applicable law
                doesn’t allow this restriction), (c) access or use the Offerings
                in a way intended to avoid incurring fees or exceeding usage
                limits or quotas, (d) use scraping techniques to mine or
                otherwise scrape data except as permitted by a Plan, or (e)
                resell or sublicense the Offerings unless otherwise agreed in
                writing. You will not use Our Marks unless you obtain our prior
                written consent. You will not misrepresent or embellish the
                relationship between us and you (including by expressing or
                implying that we support, sponsor, endorse, or contribute to you
                or your business endeavors). You will not imply any relationship
                or affiliation between us and you except as expressly permitted
                by this Agreement.
                <br />
                <br />
                7.4 Suggestions. If you provide any Suggestions to us or our
                affiliates, we and our affiliates will be entitled to use the
                Suggestions without restriction. You hereby irrevocably assign
                to us all right, title, and interest in and to the Suggestions
                and agree to provide us any assistance we require to document,
                perfect, and maintain our rights in the Suggestions.
                <br />
                <br />
                7.5 U.S. Government Users. If you are a U.S. Government End
                User, we are licensing the Offerings to you as a “Commercial
                Item” as that term is defined in the U.S. Code of Federal
                Regulations (see 48 C.F.R. § 2.101), and the rights we grant you
                to the Offerings are the same as the rights we grant to all
                others under these Terms of Use.
                <br />
                <br />
                8. Indemnification.
                <br />
                <br />
                8.1 General.
                <br />
                <br />
                (a) You will defend, indemnify, and hold harmless us, our
                affiliates and licensors, and each of their respective
                employees, officers, directors, and representatives from and
                against any Losses arising out of or relating to any claim
                concerning: (a) breach of this Agreement or violation of
                applicable law by you; or (b) a dispute between you and any of
                your customers or users. You will reimburse us for reasonable
                attorneys’ fees and expenses, associated with claims described
                in (a) and (b) above.
                <br />
                <br />
                (b) We will defend, indemnify, and hold harmless you and your
                employees, officers, directors, and representatives from and
                against any Losses arising out of or relating to any claim
                concerning our material and intentional breach of this
                Agreement. We will reimburse you for reasonable attorneys’ fees
                and expenses associated with the claims described in this
                paragraph. 8.2 Intellectual Property.
                <br />
                <br />
                (a) Subject to the limitations in this Section 8, you will
                defend 3vo, its affiliates, and their respective employees,
                officers, and directors against any third-party claim alleging
                that any of Your Content infringes or misappropriates that third
                party’s intellectual property rights, and will pay the amount of
                any adverse final judgment or settlement.
                <br />
                <br />
                (b) Subject to the limitations in this Section 8 and the
                limitations in Section 10, we will defend you and your
                employees, officers, and directors against any third-party claim
                alleging that the Offerings infringe or misappropriate that
                third party’s intellectual property rights, and will pay the
                amount of any adverse final judgment or settlement. However, we
                will not be required to spend more than $200,000 pursuant to
                this Section 8, including without limitation attorneys’ fees,
                court costs, settlements, judgments, and reimbursement costs.
                <br />
                <br />
                (c) Neither party will have obligations or liability under this
                Section 8.2 arising from infringement by you combining the
                Offerings with any other product, service, software, data,
                content or method. In addition, we will have no obligations or
                liability arising from your use of the Offerings after we have
                notified you to discontinue such use. The remedies provided in
                this Section 8.2 are the sole and exclusive remedies for any
                third-party claims of infringement or misappropriation of
                intellectual property rights by the Offerings or by Your
                Content.
                <br />
                <br />
                8.3 Process. In no event will a party agree to any settlement of
                any claim that involves any commitment, other than the payment
                of money, without the written consent of the other party.
                <br />
                <br />
                9. Disclaimers; Risk.
                <br />
                <br />
                9.1 DISCLAIMER. THE OFFERINGS ARE PROVIDED “AS IS.” EXCEPT TO
                THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY STATUTORY
                RIGHTS APPLY THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND
                OUR AFFILIATES AND LICENSORS (A) MAKE NO REPRESENTATIONS OR
                WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                OTHERWISE REGARDING THE OFFERINGS, THE THIRD PARTY CONTENT, OR
                THE THIRD PARTY SERVICES, AND (B) DISCLAIM ALL WARRANTIES,
                INCLUDING ANY IMPLIED OR EXPRESS WARRANTIES (I) OF
                MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
                PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, (II) ARISING OUT
                OF ANY COURSE OF DEALING OR USAGE OF TRADE, (III) THAT THE
                OFFERINGS, THIRD PARTY CONTENT, OR THIRD PARTY SERVICE WILL BE
                UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL COMPONENTS, AND
                (IV) THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR
                ALTERED. YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED AND
                ARE NOT RELYING UPON ANY REPRESENTATION OR WARRANTY FROM 3VO
                S.A. THAT IS NOT OTHERWISE IN THIS AGREEMENT OR IN A SEPARATE
                WRITTEN AGREEMENT BETWEEN US, AND YOU AGREE YOU WILL NOT TAKE A
                POSITION IN ANY PROCEEDING THAT IS INCONSISTENT WITH THIS
                PROVISION.
                <br />
                <br />
                9.2 RISKS. OUR OFFERINGS RELY ON EMERGING TECHNOLOGIES, SUCH AS
                ETHEREUM. SOME OFFERINGS ARE SUBJECT TO INCREASED RISK THROUGH
                YOUR POTENTIAL MISUSE OF THINGS SUCH AS PUBLIC/PRIVATE KEY
                CRYPTOGRAPHY, OR FAILING TO PROPERLY UPDATE OR RUN SOFTWARE TO
                ACCOMMODATE PROTOCOL UPGRADES. BY USING THE OFFERINGS YOU
                EXPLICITLY ACKNOWLEDGE AND ACCEPT THESE HEIGHTENED RISKS. YOU
                REPRESENT THAT YOU ARE FINANCIALLY AND TECHNICALLY SOPHISTICATED
                ENOUGH TO UNDERSTAND THE INHERENT RISKS ASSOCIATED WITH USING
                CRYPTOGRAPHIC AND BLOCKCHAIN-BASED SYSTEMS AND UPGRADING YOUR
                SOFTWARE AND PROCESSES TO ACCOMMODATE OFFERING AND PROTOCOL
                UPGRADES, AND THAT YOU HAVE A WORKING KNOWLEDGE OF THE USAGE AND
                INTRICACIES OF DIGITAL ASSETS SUCH AS ETHER (ETH) AND OTHER
                DIGITAL TOKENS, SUCH AS THOSE FOLLOWING THE ERC-20 TOKEN
                STANDARD. IN PARTICULAR, YOU UNDERSTAND THAT WE DO NOT OPERATE
                THE ETHEREUM PROTOCOL OR ANY OTHER BLOCKCHAIN PROTOCOL,
                COMMUNICATE OR EXECUTE PROTOCOL UPGRADES, OR APPROVE OR PROCESS
                BLOCKCHAIN TRANSACTIONS ON BEHALF OF YOU. YOU FURTHER UNDERSTAND
                THAT BLOCKCHAIN PROTOCOLS PRESENT THEIR OWN RISKS OF USE, THAT
                SUPPORTING OR PARTICIPATING IN THE PROTOCOL MAY RESULT IN LOSSES
                IF YOUR PARTICIPATION VIOLATES CERTAIN PROTOCOL RULES, THAT
                BLOCKCHAIN-BASED TRANSACTIONS ARE IRREVERSIBLE, THAT YOUR
                PRIVATE KEY AND SECRET RECOVERY PHRASE MUST BE KEPT SECRET AT
                ALL TIMES, THAT 3VO WILL NOT STORE A BACKUP OF, NOR WILL BE ABLE
                TO DISCOVER OR RECOVER, YOUR PRIVATE KEY OR SECRET RECOVERY
                PHRASE, THAT DIGITALLY COPYING AND STORING YOUR SECRET RECOVERY
                PHRASE ON A CLOUD STORAGE SYSTEM OR OTHER THIRD PARTY SUPPORTED
                DATA STORAGE, INCLUDING YOUR PERSONAL DEVICE, MAY INCREASE THE
                RISK OF LOSS OR THEFT, AND THAT YOU ARE SOLELY RESPONSIBLE FOR
                ANY APPROVALS OR PERMISSIONS YOU PROVIDE BY CRYPTOGRAPHICALLY
                SIGNING BLOCKCHAIN MESSAGES OR TRANSACTIONS, ESPECIALLY THOSE
                RESPONDING TO SOLICITATIONS AND OTHER PROMPTS FROM THIRD
                PARTIES.
                <br />
                <br />
                YOU FURTHER UNDERSTAND AND ACCEPT THAT DIGITAL TOKENS PRESENT
                MARKET VOLATILITY RISK, TECHNICAL SOFTWARE RISKS, REGULATORY
                RISKS, AND CYBERSECURITY RISKS. YOU UNDERSTAND THAT THE COST AND
                SPEED OF A BLOCKCHAIN-BASED SYSTEM IS VARIABLE, THAT COST MAY
                INCREASE DRAMATICALLY AT ANY TIME, AND THAT COST AND SPEED IS
                NOT WITHIN THE CAPABILITY OF 3VO TO CONTROL. YOU UNDERSTAND THAT
                PROTOCOL UPGRADES MAY INADVERTENTLY CONTAIN BUGS OR SECURITY
                VULNERABILITIES THAT MAY RESULT IN LOSS OF FUNCTIONALITY AND
                ULTIMATELY FUNDS.
                <br />
                <br />
                YOU UNDERSTAND AND ACCEPT THAT 3VO DOES NOT CONTROL ANY
                BLOCKCHAIN PROTOCOL, NOR DOES 3VO CONTROL ANY SMART CONTRACT
                THAT IS NOT OTHERWISE OFFERED BY 3VO AS PART OF THE OFFERINGS
                AND IS NOT ITSELF A THIRD PARTY SERVICE. YOU UNDERSTAND AND
                ACCEPT THAT 3VO DOES NOT CONTROL AND IS NOT RESPONSIBLE FOR THE
                TRANSITION OF ANY BLOCKCHAIN PROTOCOL FROM PROOF OF WORK TO
                PROOF OF STAKE CONSENSUS OR THE FUNCTIONING OF ANY PROTOCOL
                AFTER IT UNDERGOES A TECHNICAL UPGRADE. YOU UNDERSTAND AND
                ACCEPT THAT 3VO DOES NOT CONTROL AND IS NOT RESPONSIBLE FOR ANY
                THIRD PARTY SERVICE. YOU AGREE THAT YOU ALONE, AND NOT 3VO, IS
                RESPONSIBLE FOR ANY TRANSACTIONS THAT YOU ENGAGE IN WITH REGARD
                TO SUPPORTING ANY BLOCKCHAIN PROTOCOL WHETHER THROUGH
                TRANSACTION VALIDATION OR OTHERWISE, OR ANY TRANSACTIONS THAT
                YOU ENGAGE IN WITH ANY THIRD-PARTY-DEVELOPED SMART CONTRACT OR
                TOKEN, INCLUDING TOKENS THAT WERE CREATED BY A THIRD PARTY FOR
                THE PURPOSE OF FRAUDULENTLY MISREPRESENTING AFFILIATION WITH ANY
                BLOCKCHAIN PROJECT. YOU AGREE THAT 3VO IS NOT RESPONSIBLE FOR
                THE REGULATORY STATUS OR TREATMENT IN ANY JURISDICTION OF ANY
                DIGITAL ASSETS THAT YOU MAY ACCESS OR TRANSACT WITH USING 3VO
                OFFERINGS. YOU EXPRESSLY ASSUME FULL RESPONSIBILITY FOR ALL OF
                THE RISKS OF ACCESSING AND USING THE OFFERINGS TO INTERACT WITH
                BLOCKCHAIN PROTOCOLS.
                <br />
                <br />
                10. Limitations of Liability.
                <br />
                <br />
                10.1 Limitation of Amount. WITH THE EXCEPTION OF CLAIMS RELATING
                TO A BREACH OF OUR PROPRIETARY RIGHTS AS GOVERNED BY SECTION 7
                AND INDEMNIFICATION AS GOVERNED BY SECTION 8, IN NO EVENT SHALL
                THE AGGREGATE LIABILITY OF EACH PARTY TOGETHER WITH ALL OF ITS
                AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT
                (REGARDLESS OF WHETHER SUCH LIABILITY ARISES FROM NEGLIGENCE OR
                OTHERWISE) EXCEED THE TOTAL AMOUNT PAID BY YOU HEREUNDER FOR THE
                OFFERINGS GIVING RISE TO THE LIABILITY IN THE TWELVE MONTHS
                PRECEDING THE FIRST INCIDENT OUT OF WHICH THE LIABILITY AROSE,
                OR, IF NO FEES HAVE BEEN PAID, $25,000. THE FOREGOING LIMITATION
                WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND
                REGARDLESS OF THE THEORY OF LIABILITY, BUT WILL NOT LIMIT YOUR
                PAYMENT OBLIGATIONS UNDER SECTION 4. 3VO SHALL HAVE NO LIABILITY
                TO YOU WITH RESPECT TO ANY OFFERING EXCEPT TO THE EXTENT THAT
                SUCH DAMAGES ARE DETERMINED BY FINAL JUDGMENT OF A COURT OR
                ARBITRATOR.
                <br />
                <br />
                10.2 Exclusion of Consequential and Related Damages. IN NO EVENT
                WILL EITHER PARTY OR ITS AFFILIATES HAVE ANY LIABILITY ARISING
                OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOST PROFITS,
                REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES,
                WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE
                THEORY OF LIABILITY, EVEN IF A PARTY OR ITS AFFILIATES HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF A PARTY’S OR
                ITS AFFILIATES’ REMEDY OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE.
                THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED
                BY LAW.
                <br />
                <br />
                11. Binding Arbitration and Class Action Waiver.
                <br />
                <br />
                PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
                YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
                COURT.
                <br />
                <br />
                11.1 Binding Arbitration. Any dispute, claim or controversy
                (“Claim”) relating in any way to this Agreement, the Site, or
                your use of the Offerings will be resolved by binding
                arbitration as provided in this Section 11, rather than in
                court, except that you may assert claims in small claims court
                if your claims qualify.
                <br />
                <br />
                11.1.1 If you are located in the United States: This agreement
                and any dispute or claim (including non-contractual disputes or
                claims) arising out of or in connection with it or its subject
                matter or formation shall be governed by and construed in
                accordance with the laws of the State of Texas. The Federal
                Arbitration Act and federal arbitration law apply to this
                Agreement. There is no judge or jury in arbitration, and court
                review of an arbitration award is limited. However, an
                arbitrator can award on an individual basis the same damages and
                relief as a court (including injunctive and declaratory relief
                or statutory damages), and must follow the terms of this
                Agreement as a court would. The arbitration will be conducted in
                accordance with the expedited procedures set forth in the JAMS
                Comprehensive Arbitration Rules and Procedures (the “Rules”) as
                those Rules exist on the effective date of this Agreement,
                including Rules 16.1 and 16.2 of those Rules. The arbitrator’s
                decision shall be final, binding, and non-appealable. Judgment
                upon the award may be entered and enforced in any court having
                jurisdiction. Neither party shall sue the other party other than
                as provided herein or for enforcement of this clause or of the
                arbitrator’s award; any such suit may be brought only in a
                Federal District Court or a Texas state court located in Tarrant
                County, Texas. The arbitrator, and not any federal, state, or
                local court, shall have exclusive authority to resolve any
                dispute relating to the interpretation, applicability,
                unconscionability, arbitrability, enforceability, or formation
                of this Agreement including any claim that all or any part of
                the Agreement is void or voidable. If for any reason a claim
                proceeds in court rather than in arbitration we and you waive
                any right to a jury trial. Notwithstanding the foregoing we and
                you both agree that you or we may bring suit in court to enjoin
                infringement or other misuse of intellectual property rights.
                <br />
                <br />
                11.1.2 If you are located in the United Kingdom: This agreement
                and any dispute or claim (including non-contractual disputes or
                claims) arising out of or in connection with it or its subject
                matter or formation shall be governed by and construed in
                accordance with the law of England and Wales. Any dispute, claim
                or controversy relating in any way to this Agreement, the
                Offerings, your use of the Offerings, or to any products or
                services licensed or distributed by us will be resolved by
                binding arbitration as provided in this clause. Prior to
                commencing any formal arbitration proceedings, parties shall
                first seek settlement of any claim by mediation in accordance
                with the LCIA Mediation Rules, which Rules are deemed to be
                incorporated by reference into this clause. If the dispute is
                not settled by mediation within 14 days of the commencement of
                the mediation, or such further period as the parties shall agree
                in writing, the dispute shall be referred to and finally
                resolved by arbitration under the LCIA Rules, which are deemed
                to be incorporated by reference into this clause. The language
                to be used in the mediation and in the arbitration shall be
                English. The seat or legal place of arbitration shall be London.
                <br />
                <br />
                11.1.3 If you are located in any territory that is not
                specifically enumerated in Sections 11.1.1 or 11.1.2, you may
                elect for either of Section 11.1.1 or 11.1.2 to apply to you,
                otherwise this Agreement and any Claim (including
                non-contractual disputes or claims) arising out of or in
                connection with it or its subject matter or formation shall be
                governed by and construed in accordance with the law of Ireland.
                Any Claim relating in any way to this Agreement, the Offerings,
                your use of the Offerings, or to any products or services
                licensed or distributed by us will be resolved by binding
                arbitration as provided in this clause. Prior to commencing any
                formal arbitration proceedings, parties shall first seek
                settlement of any claim by mediation in accordance with the LCIA
                Mediation Rules, which Rules are deemed to be incorporated by
                reference into this clause. If the dispute is not settled by
                mediation within 14 days of the commencement of the mediation,
                or such further period as the parties shall agree in writing,
                the Claim shall be referred to and finally resolved by
                arbitration under the LCIA Rules, which are deemed to be
                incorporated by reference into this clause. The language to be
                used in the mediation and in the arbitration shall be English.
                The seat or legal place of arbitration shall be Dublin, Ireland.
                <br />
                <br />
                11.2 Class Action Waiver. YOU AND WE AGREE THAT EACH MAY BRING
                CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS, AND NOT AS
                A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING. YOU AND WE EXPRESSLY WAIVE ANY RIGHT
                TO FILE A CLASS ACTION OR SEEK RELIEF ON A CLASS BASIS. Unless
                both you and we agree, no arbitrator or judge may consolidate
                more than one person’s claims or otherwise preside over any form
                of a representative or class proceeding. The arbitrator may
                award injunctive relief only in favor of the individual party
                seeking relief and only to the extent necessary to provide
                relief warranted by that party’s individual claim. If a court
                decides that applicable law precludes enforcement of any of this
                paragraph’s limitations as to a particular claim for relief,
                then that claim (and only that claim) must be severed from the
                arbitration and may be brought in court. If any court or
                arbitrator determines that the class action waiver set forth in
                this paragraph is void or unenforceable for any reason or that
                an arbitration can proceed on a class basis, then the
                arbitration provision set forth above shall be deemed null and
                void in its entirety and the parties shall be deemed to have not
                agreed to arbitrate disputes.
                <br />
                <br />
                11.3 30-Day Right to Opt Out. You have the right to opt-out and
                not be bound by the arbitration and class action waiver
                provisions set forth above by sending written notice of your
                decision to opt-out to the email info@3vo.me with subject line
                LEGAL OPT OUT. The notice must be sent within 30 days of your
                first use of the Offerings, otherwise you shall be bound to
                arbitrate disputes and will be deemed to have agreed to waive
                any right to pursue a class action in accordance with the terms
                of those paragraphs. If you opt-out of these provisions, we will
                also not be bound by them.
                <br />
                <br />
                12. Miscellaneous.
                <br />
                <br />
                12.1 Assignment. You will not assign or otherwise transfer this
                Agreement or any of your rights and obligations under this
                Agreement, without our prior written consent. Any assignment or
                transfer in violation of this Section 12.1 will be void. We may
                assign this Agreement without your consent (a) in connection
                with a merger, acquisition or sale of all or substantially all
                of our assets, or (b) to any Affiliate or as part of a corporate
                reorganization; and effective upon such assignment, the assignee
                is deemed substituted for us as a party to this Agreement and we
                are fully released from all of our obligations and duties to
                perform under this Agreement. Subject to the foregoing, this
                Agreement will be binding upon, and inure to the benefit of the
                parties and their respective permitted successors and assigns.
                <br />
                <br />
                12.2 DAOs. As a blockchain native company, we may interact with
                and provide certain Offerings to DAOs. Due to the unique nature
                of DAOs, to the extent the DAO votes in favor of and/or accepts
                such Offerings from 3VO, the DAO has acknowledged and agreed to
                these Terms in their entirety.
                <br />
                <br />
                12.3 Entire Agreement and Modifications. This Agreement
                incorporates the Policies by reference and is the entire
                agreement between you and us regarding the subject matter of
                this Agreement. If the terms of this document are inconsistent
                with the terms contained in any Policy, the terms contained in
                this document will control. Any modification to the terms of
                this Agreement may only be made in writing.
                <br />
                <br />
                12.4 Force Majeure. Neither party nor their respective
                affiliates will be liable for any delay or failure to perform
                any obligation under this Agreement where the delay or failure
                results from any cause beyond such party’s reasonable control,
                including but not limited to acts of God, utilities or other
                telecommunications failures, cyber attacks, earthquake, storms
                or other elements of nature, pandemics, blockages, embargoes,
                riots, acts or orders of government, acts of terrorism, or war.
                <br />
                <br />
                12.5 Export and Sanctions Compliance. In connection with this
                Agreement, you will comply with all applicable import,
                re-import, sanctions, anti-boycott, export, and re-export
                control laws and regulations, including all such laws and
                regulations that prohibit certain transactions. For clarity, you
                are solely responsible for compliance related to the manner in
                which you choose to use the Offerings. You may not use any
                Offering if you are the subject of U.S. sanctions or of
                sanctions consistent with U.S. law imposed by the governments of
                the country where you are using the Offering.
                <br />
                <br />
                12.6 Independent Contractors; Non-Exclusive Rights. We and you
                are independent contractors, and this Agreement will not be
                construed to create a partnership, joint venture, agency, or
                employment relationship. Neither party, nor any of their
                respective affiliates, is an agent of the other for any purpose
                or has the authority to bind the other. Both parties reserve the
                right (a) to develop or have developed for it products,
                services, concepts, systems, or techniques that are similar to
                or compete with the products, services, concepts, systems, or
                techniques developed or contemplated by the other party, and (b)
                to assist third party developers or systems integrators who may
                offer products or services which compete with the other party’s
                products or services.
                <br />
                <br />
                12.7 Eligibility. If you are under the age of majority in your
                jurisdiction of residence, you may use the Site or Offerings
                only with the consent of or under the supervision of your parent
                or legal guardian.
                <br />
                <br />
                NOTICE TO PARENTS AND GUARDIANS: By granting your minor
                permission to access the Site or Offerings, you agree to these
                Terms of Use on behalf of your minor. You are responsible for
                exercising supervision over your minor’s online activities. If
                you do not agree to these Terms of Use, do not let your minor
                use the Site or Offerings.
                <br />
                <br />
                12.8 Language. All communications and notices made or given
                pursuant to this Agreement must be in the English language. If
                we provide a translation of the English language version of this
                Agreement, the English language version of the Agreement will
                control if there is any conflict.
                <br />
                <br />
                12.9 Notice.
                <br />
                <br />
                (a) To You. We may provide any notice to you under this
                Agreement using commercially reasonable means, including: (i)
                posting a notice on the Site; (ii) sending a message to the
                email address then associated with your account; (iii) posting
                the notice in the interface of the applicable Offering; or (iv)
                using public communication channels. Notices we provide by
                posting on the Site or using public communication channels will
                be effective upon posting, and notices we provide by email will
                be effective when we send the email. It is your responsibility
                to keep your email address current to the extent you have an
                account. You will be deemed to have received any email sent to
                the email address then associated with your account when we send
                the email, whether or not you actually receive the email.
                <br />
                <br />
                (b) To Us. To give us notice under this Agreement, you must
                contact us by email at info@3vo.me.
                <br />
                <br />
                12.10 No Third-Party Beneficiaries. Except as otherwise set
                forth herein, this Agreement does not create any third-party
                beneficiary rights in any individual or entity that is not a
                party to this Agreement.
                <br />
                <br />
                12.11 No Waivers. The failure by us to enforce any provision of
                this Agreement will not constitute a present or future waiver of
                such provision nor limit our right to enforce such provision at
                a later time. All waivers by us must be in writing to be
                effective.
                <br />
                <br />
                12.12 Severability. If any portion of this Agreement is held to
                be invalid or unenforceable, the remaining portions of this
                Agreement will remain in full force and effect. Any invalid or
                unenforceable portions will be interpreted to effect and intent
                of the original portion. If such construction is not possible,
                the invalid or unenforceable portion will be severed from this
                Agreement but the rest of the Agreement will remain in full
                force and effect.
                <br />
                <br />
                12.13 Notice and Procedure for Making Claims of Copyright
                Infringement. If you are a copyright owner or agent of the
                owner, and you believe that your copyright or the copyright of a
                person on whose behalf you are authorized to act has been
                infringed, please provide us a written notice at the address
                below with the following information:
                <br />
                <br />
                1. an electronic or physical signature of the person authorized
                to act on behalf of the owner of the copyright or other
                intellectual property interest;
                <br />
                <br />
                2. a description of the copyrighted work or other intellectual
                property that you claim has been infringed;
                <br />
                <br />
                3. a description of where the material that you claim is
                infringing is located with respect to the Offerings;
                <br />
                <br />
                4. your address, telephone number, and email address;
                <br />
                <br />
                5. a statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law;
                <br />
                <br />
                6. a statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright or intellectual property owner or authorized to
                act on the copyright or intellectual property owner’s behalf.
                <br />
                <br />
                You can reach us at:
                <br />
                <br />
                Email: info@3vo.me
                <br />
                Subject Line: Copyright Notification Mail
                <br />
                <br />
                3VO S.A.
                <br />
                Punta Colon 43rd Floor, Oceania Business Plaza, Tower 2000,
                Panama
                <br />
                City, Panama
                <br />
                <br />
                13. Definitions.
                <br />
                <br />
                “Acceptable Use Policy” means the policy set forth below, as it
                may be updated by us from time to time. You agree not to, and
                not to allow third parties to, use the Offerings:
                <br />
                <br />
                1. to violate, or encourage the violation of, the legal rights
                of others (for example, this may include allowing End Users to
                infringe or misappropriate the intellectual property rights of
                others in violation of the Digital Millennium Copyright Act);
                <br />
                2. to engage in, promote or encourage any illegal or infringing
                content;
                <br />
                3. for any unlawful, invasive, infringing, defamatory or
                fraudulent purpose (for example, this may include phishing,
                creating a pyramid scheme or mirroring a website);
                <br />
                4. to intentionally distribute viruses, worms, Trojan horses,
                corrupted files, hoaxes, or other items of a destructive or
                deceptive nature;
                <br />
                5. to interfere with the use of the Offerings, or the equipment
                used to provide the Offerings, by customers, authorized
                resellers, or other authorized users;
                <br />
                6. to disable, interfere with or circumvent any aspect of the
                Offerings (for example, any thresholds or limits);
                <br />
                7. to generate, distribute, publish or facilitate unsolicited
                mass email, promotions, advertising or other solicitation; or
                <br />
                8. to use the Offerings, or any interfaces provided with the
                Offerings, to access any other product or service in a manner
                that violates the terms of service of such other product or
                service.
                <br />
                “API” means an application program interface.
                <br />
                “API Requests” has the meaning set forth in Section 5.3.
                <br />
                “Applicable Threshold” has the meaning set forth in Section 4.2.
                <br />
                “Base Fees” has the meaning set forth in Section 4.2.
                <br />
                “Content” means any data, text, audio, video or images, software
                (including machine images), and any documentation.
                <br />
                “DAO” means Decentralized Autonomous Organization.
                <br />
                “End User” means any individual or entity that directly or
                indirectly through another user: (a) accesses or uses Your
                Content; or (b) otherwise accesses or uses the Offerings under
                your account.
                <br />
                “Fees” has the meaning set forth in Section 4.2.
                <br />
                “Losses” means any claims, damages, losses, liabilities, costs,
                and expenses (including reasonable attorneys’ fees).’
                <br />
                “Our Content” means any software (including machine images),
                data, text, audio, video, images, or documentation that we offer
                in connection with the Offerings.
                <br />
                “Our Marks” means any trademarks, service marks, service or
                trade names, logos, and other designations of 3vo and their
                affiliates or licensors that we may make available to you in
                connection with this Agreement.
                <br />
                “Order” means an order for Offerings executed through an order
                form directly with 3vo, or through a cloud vendor, such as
                Amazon Web Services, Microsoft Azure, or Google Cloud.
                <br />
                “Offerings” means each of the products and services, including
                but not limited to 3vo App and any other features, tools,
                materials, or services offered from time to time, by us or our
                affiliates.
                <br />
                “Policies” means the Acceptable Use Policy, Privacy Policy, any
                supplemental policies or addendums applicable to any Service as
                provided to you, and any other policy or terms referenced in or
                incorporated into this Agreement, each as may be updated by us
                from time to time.
                <br />
                “Privacy Policy” means the privacy policy located at
                https://3vo.me/ after clicking the button “Privacy Policy” in
                the footer of the site (and any successor or related locations
                designated by us), as it may be updated by us from time to time.
                <br />
                “Service Offerings” means the Services (including associated
                APIs), Our Content, Our Marks, and any other product or service
                provided by us under this Agreement. Service Offerings do not
                include Third-Party Content or Third-Party Services.
                <br />
                “Suggestions” means all suggested improvements to the Service
                Offerings that you provide to us.
                <br />
                “Term” means the term of this Agreement described in Section
                6.1.
                <br />
                “Termination Date” means the effective date of termination
                provided in accordance with Section 6, in a notice from one
                party to the other.
                <br />
                “Third-Party Content” means Content made available to you by any
                third party on the Site or in conjunction with the Offerings.
                <br />
                “Your Content” means content that you or any End User transfers
                to us for storage or hosting by the Offerings and any
                computational results that you or any End User derive from the
                foregoing through your use of the Offerings, excluding however
                any information submitted to a blockchain protocol for
                processing.
              </p>
            </p>
          </div>
          {/* <div className="embed-background">
            <img src={logo} alt="3vo-superapp"></img>
          </div> */}
        </div>
      </div>
      <FooterSection />
    </div>
  );
}

export default TermsOfUse;
