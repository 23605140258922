import React, { useEffect } from "react";
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import App from "./App";
import NewToken from "./components/otherPages/NewToken";
import NewAbout from "./components/otherPages/NewAbout";
import NewRes from "./components/otherPages/NewFeature";

import ContactUs from "./pages/contactus";
import RequestForm from "./pages/requestForms";
import Defi from "./pages/defi";
// import Presale from "./pages/presale";
import PrivacyPolicy from "./components/FooterPages/PrivacyPolicy/PrivacyPolicy";
import CSAEPolicy from "./components/FooterPages/csae/csae";
import TermsOfUse from "./components/FooterPages/TermsOfUse/TermsOfUse";
import Freedom from "./pages/freedom";
import Social from "./pages/social";
import Dapps from "./pages/dapps";
import Usecases from "./pages/usecases";
import TuckerReports from "./pages/tuckerReports";
import TastyPizza from "./pages/tastyPizza";
import FashionGabriela from "./pages/fashionGabriela";
import TheOnlyMaya from "./pages/theOnlyMaya";
import Project from "./pages/project";
import Token from "./pages/token";
import RequestUsername from "./pages/requestUsername";
import Earn from "./pages/competitions";
import Tutorial from "./pages/tutorial";
import Quests from "./pages/quest";
import PlayerOne from "./pages/playerOne";
import FitnessFlex from "./pages/fitnessFlex";
import MysteryBox from "./pages/mysteryBox";
import MysteryBoxLanding from "./pages/mystery";
import NewFeature from "./components/otherPages/NewFeature";

const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

function RedirectToExternal() {
  const { "*": redirectPath } = useParams(); // Capture wildcard path
  const location = useLocation(); // Access query parameters

  useEffect(() => {
    let redirectUrl;

    if (redirectPath) {
      // If using /re/* format
      redirectUrl = decodeURIComponent(redirectPath);
    } else {
      // If using ?target=... format
      const queryParams = new URLSearchParams(location.search);
      redirectUrl = queryParams.get("target");
    }

    if (!redirectUrl) {
      console.error("No redirect URL provided");
      return;
    }

    // Redirect after 2 seconds
    const timer = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timer
  }, [redirectPath, location]);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if the user has been redirected already
  //   const redirected = localStorage.getItem("redirected");

  //   if (!redirected) {
  //     // Set the redirected flag
  //     localStorage.setItem("redirected", "true");

  //     // Redirect to the external URL
  //     window.location.href =
  //       "https://3vo.me/mysterybox?utm_source=announcement+post&utm_medium=X&utm_campaign=Mystery+Box+Campaign&utm_id=012";
  //   } else {
  //     // If the user returns to /mb, navigate to /mysterybox
  //     localStorage.removeItem("redirected"); // Clean up the flag
  //     navigate("/mysterybox", { replace: true });
  //   }
  // }, [navigate]);

  return (
    <div>
      <MysteryBox />
      {/* Redirecting... */}
    </div>
  );
}

const RoutesFile = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      {/* NEW   */}
      <Route path="/newtoken" element={<NewToken />} />
      <Route path="/newabout" element={<NewAbout />} />
      <Route path="/newfeature" element={<NewFeature />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
      <Route path="/csae" element={<CSAEPolicy />} />
      {/* <Route path="/newresources" element={<NewRes />} /> */}
      {/* Main Subpages */}
      <Route path="/wallet" element={<Defi />} />
      <Route path="/hub" element={<Freedom />} />
      <Route path="/app" element={<Social />} />
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/dapps" element={<Dapps />} />
      <Route path="/usecases" element={<Usecases />} />
      <Route path="/project" element={<Project />} />
      <Route path="/token" element={<Token />} />
      {/* Usecases */}
      <Route path="/usecases/reporter" element={<TuckerReports />} />
      <Route path="/usecases/restaurant" element={<TastyPizza />} />
      <Route path="/usecases/fashion" element={<FashionGabriela />} />
      <Route path="/usecases/creator" element={<TheOnlyMaya />} />
      <Route path="/usecases/streamer" element={<PlayerOne />} />
      <Route path="/usecases/gym" element={<FitnessFlex />} />
      {/* Footer Subpages */}
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/forms" element={<RequestForm />} />
      <Route
        path="/presale"
        element={<ExternalRedirect url="https://forms.gle/mo8inCjMv8LDAtY3A" />}
      />
      <Route
        path="/whitelist"
        element={
          <ExternalRedirect url="https://3vo-team.odoo.com/survey/start/d58061ba-95d7-4c92-857f-e0a589b8e1e5" />
        }
      />
      {/* <Route path="/privacy" element={<PrivacyPolicy />} /> */}
      {/* <Route path="/terms" element={<TermsOfUse />} /> */}
      <Route path="/username" element={<RequestUsername />} />
      <Route path="/earn" element={<Earn />} />{" "}
      <Route path="/quests" element={<Quests />} />
      <Route path="/mysterybox" element={<MysteryBoxLanding />} />
      <Route path="/re/*" element={<RedirectToExternal />} />
      <Route
        path="/mysteryboxredirect/:boxId/:userId"
        element={<MysteryBox />}
      />
    </Routes>
  );
};

export default RoutesFile;
