import React from "react";
import "./DigitalSectionOne.css";

export const DigitalSectionOne = () => {
  return (
    <div className="about-firt-frame container">
      <div className="about-first-reactangle"></div>
      <div className="about-second-reactangle">
        <p className="about-title">The problem with Web2</p>
        <p className="about-pargraph">
          The current system for <span>creating</span>, <span>consuming</span>,
          and
          <span> transacting value</span> is <span>unfair</span> and{" "}
          <span>inefficient</span>.<span>Web2 platforms</span> controlled by
          tech giants like <span>Google </span>
          and <span>Amazon</span> monopolize <span>user data</span>, while
          <span> financial transactions</span> rely on intermediaries like{" "}
          <span>Visa </span>
          and <span>Mastercard</span>. This concentration of power leads to
          <span>censorship</span> and <span>manipulation</span>.
        </p>
      </div>
    </div>
  );
};
export default DigitalSectionOne;
