import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./FeaturesSectionOne.css";
import ellipse from "../../../../assets/svg/feature-arrow-ellipse.svg";
import arrowLeft from "../../../../assets/svg/feature-arrow-left.svg";
import arrowRight from "../../../../assets/svg/feature-arrow-right.svg";
import indictorIconActive from "../../../../assets/svg/feature-indcator-icon-active.svg";
import indictorIconNotActive from "../../../../assets/svg/feature-indcator-icon-not-active.svg";
import AiChatOpen from "../../../../assets/svg/Ai-chat-Open.png";
import AiChatOpen2 from "../../../../assets/svg/ChatOpened-2.png";
import TokenizationImage1 from "../../../../assets/svg/TokeniztionImg1.png";
import TokenizationImage2 from "../../../../assets/svg/TokeniztionImg2.png";
import TokenizationImage3 from "../../../../assets/svg/TokeniztionImg3.png";
import TokenizationImage4 from "../../../../assets/svg/TokeniztionImg4.png";
import TokenizationImage5 from "../../../../assets/svg/TokeniztionImg5.png";
import empty from "../../../../assets/svg/empty icon.svg";
import CustomizationImg1 from "../../../../assets/svg/customizationImg1.png";
import SocialImg1 from "../../../../assets/svg/SocialImg1.png";
import SocialImg2 from "../../../../assets/svg/SocialImg2.png";
import SocialImg3 from "../../../../assets/svg/SocialImg3.png";
import walletImg1 from "../../../../assets/svg/walletImg1.png";
import walletImg2 from "../../../../assets/svg/walletImg2.png";
import walletImg3 from "../../../../assets/svg/walletImg3.png";
import QuestsImg1 from "../../../../assets/svg/QuestsImg1.png";
import QuestsImg2 from "../../../../assets/svg/QuestsImg2.png";
import QuestsImg3 from "../../../../assets/svg/QuestsImg3.png";
import MonetizationImg1 from "../../../../assets/svg/MonetizationImg1.png";
import MonetizationImg2 from "../../../../assets/svg/MonetizationImg2.png";
import coinBase from "../../../../assets/svg/coinbase.svg";
import eth from "../../../../assets/svg/Eth.svg";
import solana from "../../../../assets/svg/Solana.svg";
import bnb from "../../../../assets/svg/bnb-logo.svg";

const featureCategories = {
  AI: [
    {
      title: "Introducing 3VO AI Agents: Your Digital Sidekick",
      content: [
        <strong>
          <span key="1">An AI that's truly you.</span> Train your AI Agent with
          your unique voice, humor, and even your quirks.
        </strong>,
        <strong>
          <span key="2">Always-on availability.</span> Your AI Agent is always
          there for your community, answering questions, engaging in
          conversations, and even helping to moderate discussions.
        </strong>,
        <strong>
          <span key="3">Content creation made easy.</span>Let your AI Agent
          assist with brainstorming ideas, drafting posts, generating creative
          content, and even scheduling your social media updates.
        </strong>,
      ],
      image: AiChatOpen,
    },
    {
      title: "Build Your Dream AI Team",
      content: [
        <strong>
          <span key="1">Artist: </span> specializes in image generation to
          create stunning visuals for your projects.
        </strong>,
        <strong>
          <span key="2">Music maestro: </span> Compose original soundtracks and
          generate captivating melodies.
        </strong>,
        <strong>
          <span key="3">Data strategist: </span>analyzes market trends, tracks
          your performance, and provides valuable insights to optimize your
          strategies.
        </strong>,
        <strong>
          <span key="4">A skilled negotiator on your team:</span> Hire an AI
          agent to assist with trading on behalf of your community, maximizing
          returns and optimizing your token economy. strategies.
        </strong>,
      ],
      image: AiChatOpen2,
    },
  ],
  Tokenization: [
    {
      title: "Empower Your Community with Your Own Token",
      content: [
        "Issue and trade your own custom tokens to reward loyal fans, unlock exclusive content, and build a thriving economy around your brand.",
      ],
      image: TokenizationImage1,
    },
    {
      title: "Your community are your token holders",
      content: [
        "Invite your friends and community to participate. Once liquidity requirements are met, your token is launched, empowering you to reward your supporters and unlock new possibilities for your community.",
      ],
      image: TokenizationImage2,
    },
    {
      title: "Trade User-created Tokens",
      content: [
        "Trade user-generated tokens on a decentralized marketplace. Explore a vibrant ecosystem of creator-driven tokens, acquire tokens for communities you love, and own a piece of the future of online value.",
      ],
      image: TokenizationImage3,
    },
    {
      title: "Capitalize on the attention economy",
      content: [
        "Think you have found the next big meme? Easily launch your meme coin quickly and cheaply with proper trusted contracts on multiple chains, with all the built-in social features to create communities in a few clicks.",
      ],
      image: TokenizationImage4,
    },
    {
      title: "Rug-Proof Mechanism",
      content: [
        "A portion of the initial token supply is automatically added to a decentralized exchange like Uniswap, Pancakeswap and Radium. This ensures liquidity and trading availability from the very beginning, minimizing the risk of rug pulls and promoting a healthy and sustainable token economy.",
      ],
      image: TokenizationImage5,
    },
    {
      title: "Coming soon in Q1, 2025",
      content: [
        <span key="7">
          <strong>Supported Blockchains:</strong>
        </span>,
        <div key="8" className="blockchain-support">
          <img src={eth} alt="Ethereum Logo" className="blockchain-logo" />{" "}
          <img src={bnb} alt="binance Logo" className="blockchain-logo" />{" "}
          <img src={solana} alt="binance Logo" className="blockchain-logo" />{" "}
          <img src={coinBase} alt="Ethereum Logo" className="blockchain-logo" />{" "}
          {/* <img src={eth} alt="Ethereum Logo" className="blockchain-logo" />{" "} */}
          <img src={empty} alt="Ethereum Logo" className="blockchain-logo" />{" "}
          <img src={empty} alt="binance Logo" className="blockchain-logo" />{" "}
          <img src={empty} alt="binance Logo" className="blockchain-logo-e" />{" "}
          <img src={empty} alt="Ethereum Logo" className="blockchain-logo-e" />{" "}
          <img src={empty} alt="binance Logo" className="blockchain-logo-e" />{" "}
          <img src={empty} alt="binance Logo" className="blockchain-logo-e" />{" "}
          <img src={empty} alt="Ethereum Logo" className="blockchain-logo-e" />{" "}
        </div>,
        <span key="9">
          <strong>Token Models:</strong>
        </span>,
        <strong>Pre-sale | Fair Launch</strong>,
      ],
      image: TokenizationImage5,
    },
  ],
  Monetization: [
    {
      image: MonetizationImg1,
      title: "Wishlist",
      content: [""],
      fullWidth: true,
    },
    {
      image: MonetizationImg2,
      title: "Exclusive Content",
      content: [""],
      fullWidth: true,
    },
  ],
  Customization: [
    {
      title: "Create your ultimate page",
      content: [
        "Customize your Tabs from a library of templates to create unique experiences. Enable token gated experiences and add utility for your token.",
      ],
      image: CustomizationImg1,
    },
  ],
  Quests: [
    {
      title: "A marketplace of opportunities",
      content: [
        "Explore exciting campaigns to earn rewards from creators from every corner of the world.",
      ],
      image: QuestsImg1,
    },
    {
      title: "Grow your community",
      content: [
        "Empower and grow your community through gamified rewards and custom marketing campaigns. ",
      ],
      image: QuestsImg2,
    },
    {
      title: "Level up your rank",
      content: [
        "Take on campaigns, collect achievements, and unlock exclusive rewards to boost your rank!",
        "Participate in challenges, engage with the community, and showcase your progress as you rise to the top.",
      ],
      image: QuestsImg3,
    },
  ],
  Social: [
    {
      image: SocialImg1,
      title: "Connect with Friends",
      content: [""],
      fullWidth: true,
    },
    {
      image: SocialImg2,
      title: "Send Crypto as easy as a message",
      content: [""],
      fullWidth: true,
    },
    {
      image: SocialImg3,
      title: "Communicate with Friends",
      content: [""],
      fullWidth: true,
    },
  ],

  Wallet: [
    {
      image: walletImg1,
      title: "Built-in multichain wallet",
      content: [""],
      fullWidth: true,
    },
    {
      image: walletImg2,
      title: "Receive Crypto",
      content: [""],
      fullWidth: true,
    },
    {
      image: walletImg3,
      title: "Send Crypto",
      content: [""],
      fullWidth: true,
    },
  ],
};

export const FeaturesSectionOne = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Extract category from URL, default to 'AI'
  const searchParams = new URLSearchParams(location.search);
  const initialCategory = searchParams.get("category") || "AI";

  const [activeCategory, setActiveCategory] = useState(initialCategory);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [activeCategory, setActiveCategory] = useState("AI");
  // const [currentSlide, setCurrentSlide] = useState(0);

  // Update state when URL changes
  useEffect(() => {
    setActiveCategory(initialCategory);
    setCurrentSlide(0);
  }, [initialCategory]);

  // Navigate on category click
  const handleCategoryClick = (category) => {
    navigate(`?category=${category}`); // Updates URL
    setActiveCategory(category);
    setCurrentSlide(0);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNext = () => {
    setCurrentSlide(
      (prev) => (prev + 1) % featureCategories[activeCategory].length
    );
  };

  const handlePrev = () => {
    setCurrentSlide(
      (prev) =>
        (prev - 1 + featureCategories[activeCategory].length) %
        featureCategories[activeCategory].length
    );
  };

  // const handleCategoryClick = (category) => {
  //   setActiveCategory(category);
  //   setCurrentSlide(0);
  // };

  return (
    <div id="ftCards" className="Feature-section-one container">
      <h2 className="main-feature-title">Main Features</h2>
      <div className="feature-sections-cards-wrapper">
        <div className="feature-section-card">
          {Object.keys(featureCategories).map((category) => (
            <div
              key={category}
              className={`feature-card-tag ${
                activeCategory === category ? "selected" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              <p className="feature-tag-name">{category}</p>
            </div>
          ))}
        </div>
        <div className="feature-header-body-indicator">
          <div className="feature-header">
            <p className="feature-header-title-text">
              {featureCategories[activeCategory][currentSlide].title}
            </p>
            {featureCategories[activeCategory].length > 1 && (
              <div className="feature-header-arrows">
                <div
                  className="feature-header-arrows-left"
                  onClick={handlePrev}
                >
                  <img
                    src={ellipse}
                    alt="arrow-ellipse"
                    className="feature-arrow-ellipse"
                  />
                  <img
                    src={arrowLeft}
                    alt="arrow-left"
                    className="feature-arrow-left"
                  />
                </div>
                <div
                  className="feature-header-arrows-right"
                  onClick={handleNext}
                >
                  <img
                    src={ellipse}
                    alt="arrow-ellipse"
                    className="feature-arrow-ellipse"
                  />
                  <img
                    src={arrowRight}
                    alt="arrow-right"
                    className="feature-arrow-right"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="feature-body-wrapper">
            <div className="feature-body">
              <div className="feature-inline-body">
                {/* {featureCategories[activeCategory][currentSlide].content.map(
                  (text, idx) => (
                    <p key={idx} className="feature-inline-body-text">
                      {text}
                    </p>
                  )
                )} */}
                {/* {featureCategories[activeCategory][currentSlide].content.map(
                  (text, idx) => (
                    <p
                      key={idx}
                      className="feature-inline-body-text"
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></p>
                  )
                )} */}
                {featureCategories[activeCategory][currentSlide].content.map(
                  (element, idx) => (
                    <p key={idx} className="feature-inline-body-text">
                      {element}
                    </p>
                  )
                )}
              </div>
              {/* <img
                src={featureCategories[activeCategory][currentSlide].image}
                alt="feature"
                className="ai-chat-open"
              /> */}
              <img
                src={featureCategories[activeCategory][currentSlide].image}
                alt="feature"
                className={`ai-chat-open ${
                  featureCategories[activeCategory][currentSlide].fullWidth
                    ? "full-width-image & stretched"
                    : ""
                }`}
              />
            </div>
          </div>
          <div className="feature-indicator">
            {featureCategories[activeCategory].map((_, index) => (
              <img
                key={index}
                src={
                  index === currentSlide
                    ? indictorIconActive
                    : indictorIconNotActive
                }
                alt={`indicator-${index}`}
                className={`feature-indcator-icon ${
                  index === currentSlide ? "active" : "inactive"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSectionOne;
