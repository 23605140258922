import React from "react";
import "./backedby.css";
import gains from "../../assets/svg/gains.svg";
import ibcA from "../../assets/svg/rbc.svg";
import ibcB from "../../assets/svg/ibcB.svg";
import fin from "../../assets/svg/fin.png";
import corum from "../../assets/svg/corum.png";
import redBelly from "../../assets/svg/Redbelly_Logo_white.svg";
import wehod from "../../assets/svg/wehod.png";
import gritnova from "../../assets/svg/GRITNOVA 1.svg";
export const BackedBy = () => {
  return (
    <div className="backedBy-section container">
      <h2 className="backedBy-title">
        <span>Backed</span> By
      </h2>
      <div className="backedBy-container-logos">
        <div className="backedBy-container-logos-row-1">
          <div className="backedBy-container-logos-1">
            <a href="https://gritnova.com/" target="_blank" rel="noreferrer">
              <img
                src={gritnova}
                alt="gritnova"
                className="backed-clip-logo-1"
              />
            </a>
          </div>
          <div className="backedBy-container-logos-2">
            <a
              href="https://www.gains-associates.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={gains} alt="gains" className="backed-clip-logo-2" />
            </a>
          </div>
          <div className="backedBy-container-logos-3">
            <a href="https://www.ibcgroup.io/" target="_blank" rel="noreferrer">
              <img src={ibcA} alt="lbc" className="backed-clip-logo-3-as" />
              {/* <img src={ibcB} alt="lbc" className="backed-clip-logo-3-b" /> */}
            </a>
          </div>
          <div className="backedBy-container-logos-4">
            <a
              href="https://www.finnovant.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fin} alt="finnovant" className="backed-clip-logo-4" />
            </a>
          </div>
        </div>

        <div className="backedBy-container-logos-row-2">
          <div className="backedBy-container-logos-row-2-1">
            <a href="https://corum8.com/" target="_blank" rel="noreferrer">
              <img src={corum} alt="corum" className="backed-clip-logo-2-1" />
            </a>
          </div>
          <div className="backedBy-container-logos-row-2-1">
            <a
              href="https://www.redbelly.network/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={redBelly}
                alt="redBelly"
                className="backed-clip-logo-2-2"
              />
            </a>
          </div>
          <div className="backedBy-container-logos-row-2-1">
            <a href="https://weehodl.com/" target="_blank" rel="noreferrer">
              <img src={wehod} alt="wehod" className="backed-clip-logo-2-3" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
