import React, { useState } from "react";
import "./Footer.css";
import { FaChevronDown } from "react-icons/fa"; // Import arrow icon
import Logo3vo from "../../assets/svg/3voLogo.svg";

export const FooterSection = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  // List of links with disabled status
  const footerSections = [
    {
      title: "Project",
      links: [
        { name: "About", url: "/newabout", disabled: false },
        { name: "Features", url: "/newfeature", disabled: false },
        { name: "Token", url: "/newtoken", disabled: false },
        // { name: "Careers", url: "/careers", disabled: true }, // Disabled
        {
          name: "Usecases",
          url: "https://docs.3vo.me/use-cases/overview",
          disabled: false,
          externals: true,
        },
      ],
    },
    {
      title: "Product",
      links: [
        {
          name: "3VO Hub",
          url: "https://app.3vo.me",
          disabled: false,
          externals: true,
        },
        {
          name: "3VO Mobile",
          url: "https://play.google.com/store/apps/details?id=me.threevo.client",
          disabled: false,
          externals: true,
        }, // Disabled
        {
          name: "3VOgram",
          url: "https://t.me/mysteryBox3voBot",
          disabled: false,
          externals: true,
        },
      ],
    },
    {
      title: "Resources",
      links: [
        {
          name: "Documentation",
          url: "https://docs.3vo.me",
          disabled: false,
          externals: true,
        },
        {
          name: "Blog",
          url: "https://blog.3vo.me/",
          disabled: false,
          externals: true,
        },
        // { name: "Whitepaper", url: "/#whitepaper", disabled: true },
        {
          name: "PitchDeck",
          url: "https://docsend.com/view/2tgw63cdhw64uqu5",
          disabled: false,
          externals: true,
        },
        {
          name: "Tokenomics",
          url: "https://docs.3vo.me/usd3vo-token/tokenomics",
          disabled: false,
          externals: true,
        },
      ],
    },
    {
      title: "Policies",
      links: [
        {
          name: "Privacy Policy",
          url: "/Privacy",
          disabled: false,
        },
        { name: "KYC/AML Policy", url: "/Privacy", disabled: false },
        { name: "CSAE Policy", url: "/csae", disabled: false },
        { name: "Terms of use", url: "/terms", disabled: false },
      ],
    },
    // {
    //   title: "Forms:",
    //   links: [
    //     { name: "Support", url: "https://t.me/Official_3VO", disabled: false },
    //     { name: "Partnership", url: "/#Partnership", disabled: true },
    //     { name: "Verification", url: "/#Verification", disabled: true },
    //     { name: "Token Sale", url: "/#Token-sale", disabled: true }, // Disabled
    //   ],
    // },
    {
      title: "Contact:",
      links: [
        {
          name: "Email",
          url: "mailto:info@3vo.com?subject=Support Request&body=Hello, I need help with...",
          disabled: false,
        },
        {
          name: "Community",
          url: "https://t.me/Official_3VO",
          disabled: false,
        },
      ],
    },
  ];

  return (
    <div className="Footer-wrapper">
      <div className="Footer-section-logos">
        <img src={Logo3vo} className="Footer-logo" alt="3voLogo" />
        <div className="Footer-Sections">
          {footerSections.map((section, index) => (
            <div key={index} className="Footer-Sections-Columns">
              <button
                className={`Footer-column-title ${
                  openSection === index ? "open" : ""
                }`}
                onClick={() => toggleSection(index)}
              >
                {section.title}
                <FaChevronDown
                  className={`arrow ${openSection === index ? "open" : ""}`}
                />
              </button>
              <div
                className={`Footer-column-section-wrapper ${
                  openSection === index ? "open" : ""
                }`}
              >
                {section.links.map((link, i) => (
                  <div key={i} className="Footer-column-section">
                    {link.disabled ? (
                      <span className="Footer-column-link disabled">
                        {link.name}
                      </span>
                    ) : (
                      <a
                        target={link.externals ? "blank" : ""}
                        href={link.url}
                        className="Footer-column-link"
                      >
                        {link.name}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className="Right-Reserve-text">© 2025 3VO S.A. All Rights Reserved.</p>
    </div>
  );
};
export default FooterSection;
