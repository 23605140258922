import React from "react";
import "./3voApps.css";
import arrowUp from "../../assets/appscards/arrowUp.svg";
import hub from "../../assets/appscards/hub.png";
export const AppsSections = () => {
  return (
    <div id="get3voApp" className="apps-section container">
      <div className="apps-title">
        <span>Get 3VO</span> Apps
      </div>
      <div className="apps-cards">
        <div className="app-card-hub">
          <div className="learn-more">
            <a href="https://app.3vo.me" target="_blank" rel="noreferrer">
              <p className="learn-more-text">Launch App</p>
            </a>
            <img href="#" src={arrowUp} alt="" className="arrow-up" />
          </div>
          <div className="app-cards-image-1"></div>
          <div className="app-cards-desc">
            <p className="app-cards-title">3VO Hub</p>
            <p className="app-cards-text">
              Your all-in-one platform: access every tool and feature seamlessly
              on the web.
            </p>
          </div>
        </div>
        <div className="app-card-hub">
          <div className="learn-more">
            <a
              href="https://play.google.com/store/apps/details?id=me.threevo.client"
              target="_blank"
              rel="noreferrer"
            >
              <p className="learn-more-text">Get on Google Play</p>
            </a>
            <img href="#" src={arrowUp} alt="" className="arrow-up" />
          </div>
          <div className="app-cards-image-2"></div>
          <div className="app-cards-desc">
            <p className="app-cards-title">3VO Mobile</p>
            <p className="app-cards-text">
              Your Web3 world on the go —connect, socialize, and transact with a
              multichain wallet built in.
            </p>
          </div>
        </div>
        <div className="app-card-hub">
          <div className="learn-more">
            <a
              href="https://t.me/Official_3VOgram_bot"
              target="_blank"
              rel="noreferrer"
            >
              <p className="learn-more-text">Join Now</p>
            </a>
            <img href="#" src={arrowUp} alt="" className="arrow-up" />
          </div>
          <div className="app-cards-image-3"></div>
          <div className="app-cards-desc">
            <p className="app-cards-title">3VOgram</p>
            <p className="app-cards-text">
              Use 3VO seamlessly on Telegram —create posts, earn rewards, and
              grow your network.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
